import React, { useState } from 'react'
import { Link } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'
import avater from '../images/market/avater.png'
import trend from '../images/coinDetails/trend.png'

import { mock } from 'mockjs'

const CoinDetails: React.FC = () => {
  const [collectState, setCollectState] = useState(mock('@boolean'))
  const [priceChart, setPriceChart] = useState('1H')
  const [info] = useState(
    mock({
      name: '@name',
      num: '@integer(100, 999)',
      price: '@float(-99,99,2,2)',
      percent: '@float(0,1000,2,2)',
    }),
  )
  return (
    <>
      <div className="sm:hidden">
        <Header />
      </div>
      <div className="bg-[#f1f6f9] text-sm font-regularText sm:bg-white">
        <div className="w-full bg-gradient-to-b from-[rgba(255,249,249,0.99)] to-[rgba(255,229,229,0)] bg-[length:100%_466px] bg-no-repeat overflow-hidden sm:bg-none">
          {/* Main */}
          <div className="pt-20 mx-auto max-w-7xl md:px-16 sm:px-4 sm:pt-0 sm:pb-[52px]">
            {/* Breadcrumb */}
            <div className="flex mt-5 sm:hidden">
              <Link to="/market" className="text-[#828282] mr-1.5 text-sm">
                Coin Detail
              </Link>
              <span className="text-[#828282] mr-1.5">{'  >  '}</span>
              <Link to="/market" className="text-[#1E94FC] text-sm">
                {info.name}
              </Link>
            </div>
            {/* Mobile Page Title */}
            <div className="items-center justify-between hidden mt-[26px] sm:flex h-30px">
              <Link to="/market" className="w-6 h-6 text-[#000]">
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </Link>
              <div className="text-[22px] leading-[30px] font-bold">
                Coin details
              </div>
              <svg
                viewBox="0 0 20 20"
                fill={collectState ? '#f87575' : '#D3D3D3'}
                className="w-5 h-5 ml-[13px] cursor-pointer"
                onClick={() => setCollectState(!collectState)}
              >
                <path
                  fillRule="evenodd"
                  d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            {/* Info */}
            <div className="flex justify-between mt-[54px] h-[109px] sm:mt-24px sm:h-[82px] sm:mt-6">
              {/* Left */}
              <div className="flex items-center">
                <img
                  src={avater}
                  alt="avater"
                  className="w-[109px] h-[109px] mr-[21px] sm:w-[38px] sm:h-[38px] sm:mr-4"
                />
                <div className="pt-5 pb-7 sm:pt-5 sm:pb-[22px]">
                  <div className="mb-[22px] sm:mb-[9px] flex items-center">
                    <div className="text-2xl font-boldText sm:text-[14px] sm:leading-[16px] ">
                      {info.name}
                    </div>
                    <div className="w-[58px] rounded bg-[#FBE6C8] text-[12px] leading-[12px] ml-3 px-[9px] py-[5px] text-[#F2994A] sm:py-[3px] sm:w-[54px]">
                      No.{info.num}
                    </div>
                    <svg
                      viewBox="0 0 20 20"
                      fill={collectState ? '#D3D3D3' : '#f87575'}
                      className="w-5 h-5 ml-[13px] cursor-pointer sm:hidden"
                      onClick={() => setCollectState(!collectState)}
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="text-[#828282] sm:text-[#BDBDBD] sm:sm:leading-[16px]">
                    Marrive Add Nation Token
                  </div>
                </div>
              </div>
              {/* Right */}
              <div className="flex pt-4 pb-[26px] sm:flex-col sm:p-0">
                <div className="flex flex-col justify-between mr-10 sm:m-0">
                  <div className="text-[#828282] sm:hidden">Price to USD</div>
                  <div className="text-[32px] leading-[38px] font-numberText sm:text-[18px] sm:leading-[21px] sm:pt-[13px] sm:pb-[11px]">
                    {info.percent}
                  </div>
                </div>
                <div className="flex flex-col justify-between mr-10 sm:m-0">
                  <div className="text-[#828282] sm:hidden">24h change</div>
                  <div className="font-numberText bg-[#3AD29F] text-white py-[6px] px-[7px] text-[16px] leading-[19px] flex rounded-lg sm:bg-transparent sm:text-[#3AD29F] sm:leading-[16px] sm:p-0 sm:justify-end items-center">
                    {/* up #3AD29F down #F87575 */}
                    <svg
                      className="w-[14px] h-[14px] mr-[3px]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      {true ? (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8 7l4-4m0 0l4 4m-4-4v18"
                        />
                      ) : (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 14l-7 7m0 0l-7-7m7 7V3"
                        />
                      )}
                    </svg>
                    {info.price}
                  </div>
                </div>
              </div>
            </div>
            {/* Price chart*/}
            <div className="bg-white mt-[54px] rounded-2xl py-10 px-9 sm:mt-6 sm:rounded-[0px] sm:p-0">
              <div className="flex justify-between">
                <div className="font-boldText text-[32px] sm:hidden">
                  Price chart
                </div>
                <div className="flex items-center sm:block sm:whitespace-nowrap sm:w-full sm:overflow-hidden sm:overflow-x-auto scrollbar-no-style">
                  {['1H', '1D', '1W', '1M', '1Y', 'ALL'].map((x, i) => (
                    <button
                      key={i}
                      className={`w-[54px] h-7 rounded-lg border border-solid border-[#F2F2F2] ml-[13px] sm:ml-0 sm:mr-[13px] last:mr-0 ${
                        priceChart === x ? 'text-white' : 'text-[#4F4F4F]'
                      } ${priceChart === x ? 'bg-[#1E94FC]' : 'bg-white'}`}
                      onClick={() => setPriceChart(x)}
                    >
                      {x}
                    </button>
                  ))}
                </div>
              </div>
              <div className="mt-[57px] sm:mt-[29px] sm:h-[300px]">
                <img
                  src={trend}
                  alt="trend"
                  className="w-[1156px] mx-auto sm:h-[300px]"
                />
              </div>
            </div>
            {/* Exchange*/}
            <div className="bg-white mt-[54px] rounded-2xl py-10 px-9 sm:p-0">
              <div className="flex items-center justify-between">
                <div className="font-boldText text-[32px] sm:text-[22px]">
                  Exchange
                </div>
                <div>CTIO / USDT</div>
              </div>
              <div className="mt-[41px] px-[42px] flex justify-center sm:mt-[15px] sm:rounded-2xl sm:border sm:border-solid sm:border-[#F2F2F2] sm:px-[15px] sm:py-5 sm:flex-col sm:gap-y-[30px]">
                {[
                  { title: 'Price', value: '0.3287' },
                  { title: '24H Change', value: '+20.75%	' },
                  { title: '24H Lowest', value: '0.2563' },
                  { title: '24H Highest', value: '0.3998' },
                  { title: '24H Volume', value: '27.13K CTIO' },
                ].map((x, i) => (
                  <div className="w-full sm:flex sm:justify-between">
                    <div className="text-[#828282] text-[16px] leading-[19px]">
                      {x.title}
                    </div>
                    <div
                      className={`mt-[17px] text-2xl font-numberText sm:text-[16px] sm:leading-[19px] sm:m-0 ${
                        i === 1 ? 'text-[#3AD29F]' : 'text-[#333]'
                      }`}
                    >
                      {x.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Coin Introduction*/}
            <div className="bg-white mt-[54px] mb-[62px] rounded-2xl py-10 px-9 sm:mt-[50px] sm:p-0 sm:mb-0">
              <div className="font-boldText text-[32px] sm:mb-[15px] sm:text-[22px]">
                Coin Introduction
              </div>
              <div className="mt-[37px] text-[16px] leading-[22px] text-[#333] sm:mt-0 sm:rounded-2xl sm:border sm:border-solid sm:border-[#F2F2F2] sm:py-5 sm:px-[15px]">
                <div className="mb-6">
                  Altered State Machine (ASM) provides a platform for the
                  creation and training of A.I. Agents, owned and traded using
                  NFTs. It is a new generation of metaverses and gaming, where
                  AI agents compete with each other, interact, and support human
                  actors. The ASM platform provides any application (eg: game,
                  metaverse, financial) the ability to create A.I. Agents which
                  are owned as an NFT.
                </div>
                <div className="mb-6">
                  Each Agent is represented by a combination of: Brain, which is
                  the core of the Agent; Memories, which store behavioral
                  strategies the Agent has learned (AI model training); Form,
                  which encodes physical attributes (what it looks like, and how
                  it operates).
                </div>
                <div>
                  In addition, the ASM protocol could invite other third-party
                  "form" projects (such as Meebits or Bored Apes) to upgrade
                  their NFT to include an ASM agent.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden">
        <Footer />
      </div>
    </>
  )
}

export default CoinDetails
